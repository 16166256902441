import { ApiAdapter, Mode, SearchItem } from '..'
import { Proxy, proxy, unProxy } from 'ajax-hook'

import AbstractInterceptor from './AbstractInterceptor'
import ComposeBuilder from '../Builder/ComposeBuilder'

class AjaxHookInterceptor extends AbstractInterceptor {
  private interceptConfig: Proxy

  constructor(
    searchList: SearchItem[],
    apiAdapter: ApiAdapter | undefined,
    mode: Mode,
    onRequest?: Proxy['onRequest'],
    onResponse?: Proxy['onResponse'],
    onError?: Proxy['onError']
  ) {
    super()

    this.interceptConfig = new ComposeBuilder(
      searchList,
      apiAdapter,
      mode,
      onRequest,
      onResponse,
      onError
    ).build()
  }

  public intercept(): XMLHttpRequest {
    return proxy(this.interceptConfig)
  }

  public unIntercept(): void {
    return unProxy()
  }
}

export default AjaxHookInterceptor
