import Logger, { logger } from './Logger'

import Adapter from './Adapter'
import AjaxHookInterceptor from './Interceptor/AjaxHookInterceptor'
import ParamTypeChecker from './Checker/ParamTypeChecker'
import ParamTypeError from './Error/ParamTypeError'
import { Proxy } from 'ajax-hook'
import Transformer from './Transformer'

@logger('OperationLogSdk')
class OperationLogSdk {
  // SDK 元信息
  static namespace = '__NAMESPACE__'
  static version = '__VERSION__'

  private searchList: SearchItem[]
  private ajaxHookInterceptor: AjaxHookInterceptor
  private logger: Logger | undefined

  constructor(config: Config) {
    const {
      configList,
      apiAdapter,
      mode = 'loose',
      onRequest,
      onResponse,
      onError
    } = config
    new ParamTypeChecker('configList', configList)
      .isUndefined()
      .not()
      .isArray()
      .isEmpty()
      .check()

    try {
      this.searchList = new Transformer(configList).transform()
    } catch (error) {
      throw new ParamTypeError(
        'The datastruct of the param configList is broken, please check and pass again.'
      )
    }

    this.ajaxHookInterceptor = new AjaxHookInterceptor(
      this.searchList,
      apiAdapter,
      mode,
      onRequest,
      onResponse,
      onError
    )
  }

  public getSearchList: () => SearchItem[] = () => {
    return this.searchList
  }

  public start: () => XMLHttpRequest = () => {
    this.logger?.log('Intercepting has been started')

    return this.ajaxHookInterceptor.intercept()
  }

  public stop: () => void = () => {
    this.logger?.log('Intercepting has been stopped')

    return this.ajaxHookInterceptor.unIntercept()
  }
}

export default OperationLogSdk

export function getOPInfo(config: HeadersConfig & PossibleMessage): OPInfo {
  new ParamTypeChecker('config', config).isUndefined().check()

  const { toMacHeader, toReauiredHeaders } = new Adapter(
    config?.module,
    config?.event,
    config?.id,
    config?.message
  ).adapt()

  return {
    headers: toReauiredHeaders(),
    macHeader: toMacHeader()
  }
}

export interface Config {
  configList: ConfigItem[]
  apiAdapter?: ApiAdapter
  onRequest?: Proxy['onRequest']
  onResponse?: Proxy['onResponse']
  onError?: Proxy['onError']
  mode?: Mode
}

export interface Api {
  method: HttpMethod
  path: string
}

export interface Event {
  event: string
  name?: string
  message?: string
  apis: Api[]
}

export interface ConfigItem {
  module: string
  name?: string
  events: Event[]
}

export interface SearchItem {
  path: string
  method: HttpMethod
  event: string
  message?: string
  module: string
}

export interface HasId {
  id: string
}

// https://developer.mozilla.org/zh-CN/docs/Web/HTTP/Methods
export type HttpMethod =
  | 'GET'
  | 'HEAD'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'CONNECT'
  | 'OPTIONS'
  | 'TRACE'
  | 'PATCH'

export interface PossibleRequiredHeaders {
  'x-op-module'?: string
  'x-op-event'?: string
  'x-event-id'?: string
  'x-op-message'?: string
}

export interface RequiredHeaders {
  'x-op-module': string
  'x-op-event': string
  'x-event-id': string
  'x-op-message'?: string
}

export interface HeadersConfig {
  module: string
  event: string
  id?: string
}

export interface PossibleMessage {
  message?: string
}

export type Mode = 'strict' | 'loose'

export interface OPInfo {
  headers: RequiredHeaders
  macHeader: string
}

export interface ApiAdapter {
  (url: string, headers: any): string
}
