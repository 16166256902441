import { ConfigItem, SearchItem } from '.'

import ParamTypeChecker from './Checker/ParamTypeChecker'
import { RequiredHeadersEnum } from './Enum'
import isUndefined from 'lodash-es/isUndefined'
import qs from 'qs'

class Transformer {
  private configList: ConfigItem[]
  private searchList: SearchItem[] | undefined
  private module: string | undefined

  constructor(configList: ConfigItem[]) {
    const queryObj = this.getUrlQuery()

    this.configList = configList
    this.module = queryObj[RequiredHeadersEnum.X_OP_MODULE]
  }

  private getUrlQuery(): UrlQuery {
    let query = location.href.split('?')[1]
    let queryObj = {}

    if (query) {
      if (query.indexOf('#') > -1) {
        query = query.split(/\/?#/)[0]
      }
      queryObj = qs.parse(query)
    }

    return queryObj
  }

  public transform(): SearchItem[] {
    if (isUndefined(this.searchList)) {
      this.searchList = this.configList.reduce<SearchItem[]>(
        (searchList, { module, events }) => {
          const paramTypeChecker = new ParamTypeChecker('module', module)
            .isUndefined()
            .not()
            .isString()
            .check()
            .reload('events', events)
            .isUndefined()
            .not()
            .isArray()
            .isEmpty()
            .check()

          return searchList.concat(
            events.reduce<SearchItem[]>(
              (subSearchList, { event, message, apis }) => {
                paramTypeChecker
                  .reload('event', event)
                  .isUndefined()
                  .not()
                  .isString()
                  .check()
                  .reload('apis', apis)
                  .isUndefined()
                  .not()
                  .isArray()
                  .isEmpty()
                  .check()

                return subSearchList.concat(
                  apis.reduce<SearchItem[]>(
                    (subSubSearchList, { method, path }) => {
                      paramTypeChecker
                        .reload('method', method)
                        .isUndefined()
                        .not()
                        .isString()
                        .check()
                        .reload('path', path)
                        .isUndefined()
                        .not()
                        .isString()
                        .check()

                      const searchItem: SearchItem = {
                        module: this.module || module,
                        event,
                        method,
                        path
                      }

                      if (!isUndefined(message)) {
                        paramTypeChecker
                          .reload('message', message)
                          .not()
                          .isString()
                          .check()

                        searchItem.message = message
                      }

                      return subSubSearchList.concat(searchItem)
                    },
                    []
                  )
                )
              },
              []
            )
          )
        },
        []
      )
    }

    return this.searchList
  }
}

export default Transformer

interface UrlQuery {
  'x-op-module'?: string
}
