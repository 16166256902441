import { HasId, PossibleRequiredHeaders, SearchItem } from '.'
import Logger, { logger } from './Logger'

import { RequiredHeadersEnum } from './Enum'
import filter from 'lodash-es/filter'
import isEmpty from 'lodash-es/isEmpty'
import isUndefined from 'lodash-es/isUndefined'
import toUpper from 'lodash-es/toUpper'
import { v4 as uuidv4 } from 'uuid'

const HOST_REG = /(https?:)*\/\/[\w|.|\-|:]+/
const PARAMS_REG = /\?.*/
const PLACEHOLDER_REG = /{[^/]+}/g

@logger('Matcher')
class Matcher {
  private searchList: SearchItem[]
  logger: Logger | undefined

  constructor(searchList: SearchItem[]) {
    this.searchList = searchList
  }

  private createReg(path: string): RegExp {
    return new RegExp(path.replace(PLACEHOLDER_REG, '[^/]+'))
  }

  public formatHeaders(
    headers: PossibleRequiredHeaders
  ): PossibleRequiredFormattedHeaders {
    const formattedHeaders: PossibleRequiredFormattedHeaders = {}

    if (!isUndefined(headers[RequiredHeadersEnum.X_OP_MODULE])) {
      formattedHeaders.module = headers[RequiredHeadersEnum.X_OP_MODULE]
    }

    if (!isUndefined(headers[RequiredHeadersEnum.X_OP_EVENT])) {
      formattedHeaders.event = headers[RequiredHeadersEnum.X_OP_EVENT]
    }

    if (!isUndefined(headers[RequiredHeadersEnum.X_OP_MESSAGE])) {
      formattedHeaders.message = headers[RequiredHeadersEnum.X_OP_MESSAGE]
    }

    return formattedHeaders
  }

  private getUuid(headers: PossibleRequiredHeaders): string {
    return isUndefined(headers[RequiredHeadersEnum.X_EVENT_ID])
      ? uuidv4()
      : (headers[RequiredHeadersEnum.X_EVENT_ID] as string)
  }

  public match(
    method: string,
    url: string,
    headers: PossibleRequiredHeaders
  ): SearchItem[] | (SearchItem & HasId) | null {
    const path = url.replace(HOST_REG, '').replace(PARAMS_REG, '')
    const formattedHeaders = this.formatHeaders(headers)
    const id = this.getUuid(headers)

    const uniqueSearchItems = filter(
      this.searchList,
      ({ method: searchItemMethod, path: searchItemPath, module, event }) => {
        let matched =
          toUpper(searchItemMethod) === toUpper(method) &&
          path.replace(this.createReg(searchItemPath), '').length === 0

        if (!isUndefined(formattedHeaders.module)) {
          matched = matched && formattedHeaders.module === module
        }

        if (!isUndefined(formattedHeaders.event)) {
          matched = matched && formattedHeaders.event === event
        }

        return matched
      }
    )

    this.logger?.log('MatchedSearchItems: ', uniqueSearchItems)

    return isEmpty(uniqueSearchItems)
      ? null
      : uniqueSearchItems.length === 1
      ? {
          ...uniqueSearchItems[0],
          id
        }
      : uniqueSearchItems
  }
}

export default Matcher

interface PossibleRequiredFormattedHeaders {
  module?: string
  event?: string
  message?: string
}
