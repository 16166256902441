import { ApiAdapter, Mode, SearchItem } from '..'
import { Proxy, XhrRequestConfig, XhrRequestHandler } from 'ajax-hook'

import AbstractBuilder from './AbstractBuilder'
import Processer from '../Processer/RequestProcesser'

class RequestBuilder extends AbstractBuilder {
  private searchList: SearchItem[]
  private mode: Mode
  private apiAdapter: ApiAdapter | undefined
  private onRequest:
    | ((config: XhrRequestConfig, handler: XhrRequestHandler) => void)
    | undefined

  constructor(
    searchList: SearchItem[],
    apiAdapter: ApiAdapter | undefined,
    mode: Mode,
    onRequest?: Proxy['onRequest']
  ) {
    super()

    this.searchList = searchList
    this.apiAdapter = apiAdapter
    this.mode = mode
    this.onRequest = onRequest
  }

  public build(): Proxy['onRequest'] {
    return (config: XhrRequestConfig, handler: XhrRequestHandler) =>
      new Processer(this.searchList, config, handler, this.mode, this.onRequest)
        .extractRequireHeaders()
        .findUniqueSearchItem(this.apiAdapter)
        .process()
  }
}

export default RequestBuilder
