class Logger {
  private namespace = '__NAMESPACE__'
  private version = '__VERSION__'
  private clazz: string
  private console: Console

  constructor(clazz: string) {
    this.clazz = clazz
    this.console = window.console
  }

  public log(message: any, ...optionalParams: any[]): void {
    return this.console.log(
      `[${this.namespace}@${this.version}]->[${this.clazz}]: ${message}`,
      ...optionalParams
    )
  }

  public warn(message: any, ...optionalParams: any[]): void {
    return this.console.warn(
      `[${this.namespace}@${this.version}]->[${this.clazz}]: ${message}`,
      ...optionalParams
    )
  }

  public error(message: any, ...optionalParams: any[]): void {
    return this.console.error(
      `[${this.namespace}@${this.version}]->[${this.clazz}]: ${message}`,
      ...optionalParams
    )
  }
}

export default Logger

export function logger(clazz: string) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return function (constructor: Function): void {
    constructor.prototype.logger = new Logger(clazz)
  }
}
